/* eslint-disable no-console */
import { unwrapResult } from '@reduxjs/toolkit'
import fvIconSmall from 'assets/icons/iconFvSmall.svg'
import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import InputIncrementer from 'components/_inputs/InputIncrementer'
import ModalCardsPrinting from 'components/_modals/ModalCardsPrinting'
import ModalInvoicePrinting from 'components/_modals/ModalInvoicePrinting'
import ReservationSteps from 'components/_views/ReservationDetailsSteps/ReservationSteps'
import { RESERVATION_STEP_PRINT_CARDS } from 'constants/app'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { moveToGate } from 'redux/slices/reservation/reservationSlice'
import { dispatch, RootState } from 'redux/store'
import {
  BackCard,
  RoomCard,
} from 'views/kiosk/makeReservation/ViewMakeReservationPrintCardKiosk.styled'

const ViewMakeReservationPrintCardKiosk = () => {
  const { t } = useTranslation()
  const bookingDetails = useSelector(
    (state: RootState) => state.booking.bookingDetails?.data ?? null
  )
  const cardsForm = useForm({
    defaultValues: {
      cardsAmount: 1,
    },
  })
  const [isCardPrintingModalOpen, setIsCardPrintingModalOpen] = useState(false)
  const [isInvoicePrintingModalOpen, setIsInvoicePrintingModalOpen] =
    useState(false)

  const handlePrintCard = async () => {
    setIsCardPrintingModalOpen(true)
    try {
      const resultAction = await dispatch(moveToGate())
      const result = unwrapResult(resultAction)

      if (result.success) {
        console.log('success')
      } else {
        console.error('Nie udało się przenieść karty do bramy:', result.message)
      }
    } catch (err) {
      console.error('Błąd podczas przenoszenia karty do bramy:', err)
    }
  }

  return (
    <Common.Div pt={7}>
      <Common.Div
        alignItems="center"
        flex="column"
      >
        <ReservationSteps
          step={RESERVATION_STEP_PRINT_CARDS}
          withStepBars={false}
        />
      </Common.Div>
      <Common.Div
        flex="column"
        alignItems="center"
      >
        <Typography.H4
          mb={2}
          secondary
        >
          {t('cardPrinting.readyForPrinting')}
        </Typography.H4>
        <Typography.Body1
          secondary
          mb={2}
        >
          {t('cardPrinting.howMany')}
        </Typography.Body1>
        <InputIncrementer
          name="cardsAmount"
          form={cardsForm}
        />
        <Common.Div
          flex="row"
          mb={3}
          mt={2}
        >
          <RoomCard />
          <BackCard />
        </Common.Div>
        <Common.Div fullyCentered>
          <Button
            type="button"
            onClick={handlePrintCard}
          >
            <Common.SvgIcon
              src={fvIconSmall}
              mr={2}
            />
            {t('cardPrinting.modal.printButton')}
          </Button>
        </Common.Div>
      </Common.Div>
      <ModalCardsPrinting
        bookingDetails={bookingDetails}
        isOpen={isCardPrintingModalOpen}
        setInvoicePrintingModalIsOpen={setIsInvoicePrintingModalOpen}
        setIsOpen={setIsCardPrintingModalOpen}
      />
      <ModalInvoicePrinting
        isOpen={isInvoicePrintingModalOpen}
        setIsOpen={setIsInvoicePrintingModalOpen}
      />
    </Common.Div>
  )
}

export default ViewMakeReservationPrintCardKiosk
